export default {
	en: {
		short: {
			year: "numeric",
			month: "short",
			day: "numeric",
		},
		long: {
			year: "numeric",
			month: "short",
			day: "numeric",
			weekday: "short",
			hour: "numeric",
			minute: "numeric",
		},
	},
	es: {
		short: {
			year: "numeric",
			month: "short",
			day: "numeric",
		},
		long: {
			year: "numeric",
			month: "short",
			day: "numeric",
			weekday: "short",
			hour: "numeric",
			minute: "numeric",
		},
	},
};
